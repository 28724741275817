import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import Facebook from "./Facebook"
import Twitter from "./Twitter"
import i18n from "../../../config/i18n"

const SEO = ({
  metadata,
  title,
  desc,
  banner,
  pathname,
  article,
  locale,
  node,
}) => {
  const { defaultTitle, defaultDescription, siteLanguage, headline } = i18n[
    locale
  ]
  // prettier-ignore
  const {banner: defaultBanner,siteUrl,ogLanguage,author,twitter,facebook  } = metadata

  const localizedPath = i18n[locale].default ? "" : `/${i18n[locale].path}`
  const homeURL = `${siteUrl}${localizedPath}`

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ""}`,
  }

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: defaultTitle,
    url: homeURL,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: homeURL,
    description: defaultDescription,
    author: {
      "@type": "Organization",
      name: author,
    },
    copyrightHolder: {
      "@type": "Organization",
      name: author,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Organization",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: author,
    },
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
    {
      "@type": "ListItem",
      item: {
        "@id": `${homeURL}/blog`,
        name: "blog",
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Organization",
        name: author,
      },
      copyrightHolder: {
        "@type": "Organization",
        name: author,
      },
      copyrightYear: "2020",
      creator: {
        "@type": "Organization",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node._meta.firstPublicationDate,
      dateModified: node._meta.lastPublicationDate,
      description: seo.description,
      headline: seo.title,
      inLanguage: locale,
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }

  const segmentreport = !function(){
    if (typeof window === 'undefined')
      return;

      //Mise en attente pour test autre segment
      // var analytics=window.analytics=window.analytics||[];
      // if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");
      // else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];
      // analytics.factory=function(e){
      //   return function(){
      //     var t=Array.prototype.slice.call(arguments);
      //     t.unshift(e);analytics.push(t);return analytics
      //     }
      //     };
      //     for(var e=0;e<analytics.methods.length;e++){
      //       var key=analytics.methods[e];analytics[key]=analytics.factory(key)
      //       }analytics.load=function(key,e){
      //         var t=document.createElement("script");
      //         t.type="text/javascript";
      //         t.async=!0;
      //         t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
      //         var n=document.getElementsByTagName("script")[0];
      //         n.parentNode.insertBefore(t,n);analytics._loadOptions=e
      //         };
      //         analytics.SNIPPET_VERSION="4.13.1";
      //          analytics.load("2YYOdCAiXEWeWYP0tNY1ewdwnR8g5I6A");
      //           analytics.page();
      //            }
      //            }();

      var analytics=window.analytics=window.analytics||[];
      if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");
      else{
        analytics.invoked=!0;
        analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];
        analytics.factory=function(e){
          return function(){
            var t=Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics}
          };
        for(var e=0;e<analytics.methods.length;e++){
          var key=analytics.methods[e];analytics[key]=analytics.factory(key)
        }
        analytics.load=function(key,e){
          var t=document.createElement("script");
          t.type="text/javascript";
          t.async=!0;
          t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
          var n=document.getElementsByTagName("script")[0];
          n.parentNode.insertBefore(t,n);analytics._loadOptions=e
        };
        analytics.SNIPPET_VERSION="4.13.1";
        analytics.load("ip9m5IWKRdrwhFJuhxBAr7Nmo6w271jO");
        analytics.page();
      }
    }();


  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(breadcrumb)}
          </script>
        )}

         <script>
          {JSON.stringify(segmentreport)}
        </script>

        {/* <script 
          id="ze-snippet" 
          src="https://static.zdassets.com/ekr/snippet.js?key=6210f00d-b8a3-4a76-86d6-7862f0066c35">
        </script> */}

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-92829588-2"></script> */}


      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? "article" : "website"}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  locale: PropTypes.string,
}
